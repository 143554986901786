const cloneFile = (file) => {
  if (file instanceof File) {
    return new File([file], file.name, {
      type: file.type,
      lastModified: file.lastModified,
    })
  }
  return file
}
export default cloneFile
